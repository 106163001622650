import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-info">
        <div>IP: thegrove-mc.com</div>
        <div>
          <a
            href="https://discord.gg/dr6bNSG6"
            target="_blank"
            rel="noopener noreferrer"
          >
            Discord
          </a>
        </div>
        <div>
          <Link to="/terms">Terms of Service</Link>
        </div>
      </div>
      <div className="footer-copyright">
        &copy; {new Date().getFullYear()} The Grove MC. All rights reserved.
      </div>
    </div>
  );
};

export default Footer;
