import React from "react";

const TermsOfService = () => {
  return (
    <div className="terms-of-service">
      <div className="content">
        <h1>Terms of Service</h1>
        <p className="terms-intro">
          Please read these terms of service ("terms", "terms of service")
          carefully before using the The Grove Minecraft server and website
          (collectively, the "Services") operated by The Grove MC ("us", "we",
          or "our").
        </p>

        <h2 className="conditions">Conditions of Use</h2>
        <p>
          By using our Services, you confirm that you are at least 13 years of
          age and you agree to be bound by these terms. If you do not agree with
          any part of these terms, you must not use our Services.
        </p>

        <h2>Donations and In-game Perks</h2>
        <p>
          We provide the option to make donations through Stripe. When you make
          a donation, you may receive in-game perks as a token of our
          appreciation. However, these perks are not guaranteed and are provided
          at our sole discretion. We are not liable for any issues or loss of
          perks due to problems on our end, Stripe's end, or the user's end.
        </p>

        <h2>User Conduct</h2>
        <p>
          Users are expected to behave respectfully and follow any rules set
          forth by the server. We reserve the right to ban or mute users who
          violate these rules or engage in any behavior we deem inappropriate,
          at our sole discretion.
        </p>

        <h2>Intellectual Property</h2>
        <p>
          All content on the website and server, including but not limited to
          text, images, graphics, and code, is the property of The Grove MC and
          is protected by copyright and other intellectual property laws. You
          may not use, copy, reproduce, modify, or distribute any of this
          content without our express written permission.
        </p>

        <h2>Disclaimer and Limitation of Liability</h2>
        <p>
          The Grove MC provides the Services on an "as is" and "as available"
          basis. We make no warranties, express or implied, regarding the
          availability, reliability, or functionality of the Services. To the
          fullest extent permitted by law, we disclaim all warranties, including
          but not limited to implied warranties of merchantability, fitness for
          a particular purpose, and non-infringement.
        </p>
        <p>
          In no event shall The Grove MC be liable for any direct, indirect,
          incidental, special, consequential, or exemplary damages, including
          but not limited to lost profits, loss of data, or other intangible
          losses, resulting from the use or inability to use the Services, even
          if we have been advised of the possibility of such damages. Our total
          liability to you for any claim arising out of or relating to these
          terms or the Services, whether in contract, tort, or otherwise, shall
          not exceed the amount you have paid to us in the past six months, or,
          if greater, one hundred dollars ($100).
        </p>

        <h2>Indemnification</h2>
        <p>
          You agree to indemnify, defend, and hold harmless The Grove MC, its
          affiliates, and their respective officers, directors, employees, and
          agents from and against any and all claims, liabilities, damages,
          losses, or expenses, including reasonable attorneys' fees and costs,
          arising out of or in any way connected with your access to or use of
          the Services.
        </p>

        <h2>Governing Law and Jurisdiction</h2>
        <p>
          These terms and any dispute or claim arising out of or in connection
          with them or their subject matter or formation shall be governed by
          and construed in accordance with the laws of the Commonwealth of
          Kentucky, United States. You and The Grove MC agree to submit to the
          exclusive jurisdiction of the courts of the Commonwealth of Kentucky,
          United States, to resolve any legal matter arising from these terms or
          your use of the Services.
        </p>

        <h2>Changes to These Terms</h2>
        <p>
          We reserve the right, at our sole discretion, to modify or replace
          these terms at any time. If a revision is material, we will provide at
          least 7 days' notice prior to any new terms taking effect. What
          constitutes a material change will be determined at our sole
          discretion. Your continued use of the Services after any such changes
          constitutes your acceptance of the new terms. If you do not agree to
          any of these terms or any future version of the terms, do not use or
          access the Services.
        </p>

        <h2>Contact Us</h2>
        <p>
          If you have any questions about these terms, please contact us through
          our Discord server or at thegrovemcteam@gmail.com.
        </p>
      </div>
    </div>
  );
};

export default TermsOfService;
