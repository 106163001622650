import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import treeImage from "./treeImage.png"; // replace with your tree image path
import ".//HomePage.css";

const Homepage = () => {
  useEffect(() => {
    // Add a delay before fading in the buttons
    setTimeout(() => {
      const buttons = document.querySelectorAll(".btn");
      buttons.forEach((button) => button.classList.add("fade-in"));
    }, 500);
  }, []);

  return (
    <div className="homepage" id="homepage">
      <div className="header-image">
        <img src={treeImage} className="treeImage" alt="The Grove" />
        <div className="server-info fade-in">
          <div className="header-text">The&nbsp;Grove</div>
          <div className="header-ip">IP: thegrove-mc.com</div>
          <div className="header-type">Semi-Anarchy</div>
        </div>

        <div className="discord-link">
          <a
            href="https://discord.gg/dr6bNSG6"
            target="_blank"
            rel="noreferrer" // Update the link
            className="btn btn-discord"
            id="discord"
          >
            Join Discord
          </a>
        </div>
        <div className="donate-link">
          <Link to="/donate" className="btn btn-donate" id="donate">
            Donate
          </Link>
        </div>
      </div>
      <div className="content">
        <div className="buffer" id="introduction">
          <div className="introduction">
            <h2>Welcome to The Grove!</h2>
            <p>
              Step into the thrilling world of The Grove, where classic
              Minecraft survival meets high-stakes challenges. Our server is
              designed for players seeking adventure, risk, and strategic
              gameplay. Forge alliances or dominate as a ruthless dictator -
              your fate lies in your hands.
            </p>
          </div>
        </div>
        <div className="buffer" id="tutorial">
          <div className="tutorial">
            <h2>Getting Started</h2>
            <p>
              Join the server using the IP: thegrove-mc.com and start your
              adventure in our semi-anarchy world. Familiarize yourself with our
              unique features and commands to fully immerse in the experience.
            </p>

            <h2>Useful Commands</h2>
            <ul>
              <li>/rtp - Randomly teleport to a new location</li>
              <li>/kits - Access available kits</li>
              <li>/jobs - Join jobs to earn money</li>
              <li>/warp shop - Warp to the in-game shop</li>
              <li>/vote - Vote for the server and receive rewards</li>
              <li>/claim - Claim land (limited to 25 claim blocks total)</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Homepage;
