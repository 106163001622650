import React, { useState } from "react";
import { Link } from "react-router-dom";

const DonationPage = () => {
  const [selectedKit, setSelectedKit] = useState(null);

  const ranks = [
    {
      name: "Bamboo",
      displayName: "[Bamboo]",
      level: 1,
      perks: ["Weekly /kit Bamboo"],
      price: 10,
      stripeLink: "https://buy.stripe.com/test_eVaaEY16he8S3sYaEI",
      color: "lime",
    },
    {
      name: "Cedar",
      displayName: "[Cedar]",
      level: 2,
      perks: ["Weekly /kit Cedar", "/hat", "/sethome 2"],
      price: 20,
      stripeLink: "https://buy.stripe.com/test_14kdRabKV3ueaVq003",
      color: "aqua",
    },
    {
      name: "Ginkgo",
      displayName: "[Ginkgo]",
      level: 3,
      perks: ["Weekly /kit Ginkgo", "/hat", "/nick", "/sethome 3"],
      price: 40,
      stripeLink: "https://buy.stripe.com/test_8wMcN616h7KubZu9AC",
      color: "red",
    },
    {
      name: "Redwood",
      displayName: "[Redwood]",
      level: 4,
      perks: [
        "Weekly /kit Redwood",
        "/hat",
        "/nick",
        "/sethome 4",
        "/enderchest",
      ],
      price: 60,
      stripeLink: "https://buy.stripe.com/test_14k7sM16h2qa1kQcMN",
      color: "purple",
    },
    {
      name: "AncientOak",
      displayName: "[AncientOak]",
      level: 5,
      perks: [
        "Weekly /kit AncientOak",
        "/hat",
        "/nick",
        "/sethome unlimited",
        "/enderchest",
        "/feed",
      ],
      price: 80,
      stripeLink: "https://buy.stripe.com/test_14kaEY9CNfcW0gMfYY",
      color: "gold",
    },
  ];
  const kits = {
    Bamboo: [
      "Diamond Helmet",
      "Diamond Chestplate",
      "Diamond Leggings",
      "Diamond Boots",
      "Diamond Sword (Sharpness II, Unbreaking III)",
      "Diamond Pickaxe (Efficiency IV, Unbreaking III)",
      "Diamond Axe (Efficiency III, Unbreaking III)",
      "Diamond Shovel (Efficiency III, Unbreaking III)",
      "64x Cooked Beef",
      "2x Golden Apple",
    ],
    Cedar: [
      "Diamond Helmet (Protection IV, Unbreaking III)",
      "Diamond Chestplate (Protection IV, Unbreaking III)",
      "Diamond Leggings (Protection IV, Unbreaking III)",
      "Diamond Boots (Protection IV, Unbreaking III)",
      "Diamond Sword (Sharpness V, Unbreaking III)",
      "Diamond Pickaxe (Efficiency V, Unbreaking III, Fortune II)",
      "Diamond Axe (Efficiency V, Unbreaking III)",
      "Diamond Shovel (Efficiency V, Unbreaking III)",
      "64x Cooked Beef",
      "4x Golden Apple",
    ],
    Ginkgo: [
      "Diamond Helmet (Protection IV, Unbreaking III)",
      "Diamond Chestplate (Protection IV, Unbreaking III)",
      "Diamond Leggings (Protection IV, Unbreaking III)",
      "Diamond Boots (Protection IV, Unbreaking III)",
      "Netherite Sword (Sharpness V, Unbreaking III)",
      "Netherite Pickaxe (Efficiency V, Unbreaking III, Fortune III)",
      "Diamond Axe (Efficiency V, Unbreaking III)",
      "Diamond Shovel (Efficiency V, Unbreaking III)",
      "64x Cooked Beef",
      "6x Golden Apple",
      "1x Shulker Box",
    ],
    Redwood: [
      "Netherite Helmet (Protection IV, Unbreaking III, Mending I)",
      "Netherite Chestplate (Protection IV, Unbreaking III, Mending I)",
      "Netherite Leggings (Protection IV, Unbreaking III, Mending I)",
      "Netherite Boots (Protection IV, Unbreaking III, Mending I)",
      "Netherite Sword (Sharpness V, Unbreaking III, Mending I)",
      "Netherite Pickaxe (Efficiency V, Unbreaking III, Fortune III, Mending I)",
      "Netherite Axe (Efficiency V, Unbreaking III, Mending I)",
      "Netherite Shovel (Efficiency V, Unbreaking III, Mending I)",
      "64x Cooked Beef",
      "8x Golden Apple",
      "1x Enchanted Golden Apple",
      "2x Shulker Box",
    ],
    AncientOak: [
      "Netherite Helmet (Protection IV, Unbreaking III, Mending I)",
      "Netherite Chestplate (Protection IV, Unbreaking III, Mending I)",
      "Netherite Leggings (Protection IV, Unbreaking III, Mending I)",
      "Netherite Boots (Protection IV, Unbreaking III, Mending I)",
      "Netherite Sword (Sharpness V, Unbreaking III, Mending I)",
      "Netherite Pickaxe (Efficiency V, Unbreaking III, Fortune III, Mending I)",
      "Netherite Axe (Efficiency V, Unbreaking III, Mending I)",
      "Netherite Shovel (Efficiency V, Unbreaking III, Mending I)",
      "Bow (Power V, Unbreaking III, Punch II, Flame I, Infinity I, Mending I)",
      "Trident (Impaling V, Unbreaking III, Loyalty III, Riptide III, Mending I)",
      "64x Cooked Beef",
      "10x Golden Apple",
      "2x Enchanted Golden Apple",
      "4x Shulker Box",
      "1x Beacon",
      "3x Villager Spawn Egg",
    ],
  };

  const handleOnClick = () => {
    console.log("Clicked a rank card!");
  };

  const handleViewKit = (kitName) => {
    if (selectedKit === kitName) {
      setSelectedKit(null);
    } else {
      setSelectedKit(kitName);
    }
  };

  return (
    <div className="donation-page">
      <h1>Support Our Server</h1>
      <p className="donation-intro">
        Thank you for considering a donation! Your support helps us maintain and
        improve our server. As a token of appreciation, we offer various ranks
        and perks for our donors. Please read the{" "}
        <Link to="/terms" className="terms-link">
          terms of service
        </Link>{" "}
        before donating.
      </p>
      <div className="ranks-container">
        {ranks.map((rank, index) => (
          <div key={index} className="rank-card" onClick={handleOnClick}>
            <h2 style={{ color: rank.color }}>[{rank.name}]</h2>
            <h3>${rank.price}</h3>
            <div className="perks-wrapper">
              <ul className="perks-list">
                {rank.perks.map((perk, i) => (
                  <li key={i}>
                    {perk}
                    <br></br>
                    {perk.startsWith("Weekly") && (
                      <button
                        className="btn btn-view-kit"
                        onClick={() => handleViewKit(rank.name)}
                      >
                        {selectedKit === rank.name ? "Hide Kit" : "View Kit"}
                      </button>
                    )}
                  </li>
                ))}
              </ul>
            </div>
            {selectedKit === rank.name && (
              <div className="kit-display">
                <h2>Kit Items:</h2>
                <ul className="kit-list">
                  {kits[rank.name].map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
              </div>
            )}
            <div className="donate-button-wrapper">
              <a
                href={rank.stripeLink}
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-donate"
              >
                Donate
              </a>
            </div>
          </div>
        ))}
      </div>
      <div className="disclaimer">
        <p>
          By donating, you acknowledge that this is purely a donation and that
          we will make our best efforts to provide you with the in-game perks as
          quickly as possible. However, if there are any errors on our part, the
          part of Stripe, or the part of the donor, we are not held liable for
          any of the perks not being received.
        </p>
      </div>
      <Link to="/" className="btn btn-back">
        Go Back
      </Link>
    </div>
  );
};

export default DonationPage;
