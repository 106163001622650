import { Link, useLocation } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import homeIcon from "./homeIcon.png";

const Navbar = () => {
  const location = useLocation();

  const isHomePage = location.pathname === "/";

  const handleClick = (e, id) => {
    e.preventDefault();
    if (window.location.pathname !== "/") {
      window.location.href = "/";
    }
    setTimeout(() => {
      if (id === "home") {
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else {
        const elem = document.getElementById(id);
        if (elem) {
          elem.scrollIntoView({ behavior: "smooth" });
        }
      }
    }, 0);
  };

  return (
    <nav className="navbar">
      <ul className="navbar-list">
        <li>
          <Link to="/" onClick={(e) => handleClick(e, "home")}>
            <img
              className="home-icon"
              src={homeIcon}
              alt="text that says The Grove"
            />
          </Link>
        </li>
        {isHomePage && (
          <>
            <ScrollLink
              to="introduction"
              smooth={true}
              duration={500}
              className="navbar-link" // add a class name to apply styles
            >
              Introduction
            </ScrollLink>

            <ScrollLink
              to="tutorial"
              smooth={true}
              duration={500}
              className="navbar-link" // add a class name to apply styles
            >
              Tutorial
            </ScrollLink>
          </>
        )}
        {!isHomePage && (
          <>
            <li>
              <Link to="/" onClick={(e) => handleClick(e, "introduction")}>
                Introduction
              </Link>
            </li>
            <li>
              <Link to="/" onClick={(e) => handleClick(e, "tutorial")}>
                Tutorial
              </Link>
            </li>
          </>
        )}
        <li>
          <Link to="/donate">Donate</Link>
        </li>
        <li>
          <a
            href="https://discord.gg/dr6bNSG6"
            target="_blank"
            rel="noopener noreferrer"
          >
            Discord
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
