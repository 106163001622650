import React from "react";
import { Routes, Route } from "react-router-dom";
import Homepage from "./HomePage";
import DonationPage from "./DonationPage";
import Navbar from "./Navbar";
import Footer from "./Footer";
import TermsOfService from "./TermsOfService";
import "./App.css";

function App() {
  return (
    <div className="App">
      <Navbar />
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/donate" element={<DonationPage />} />
        <Route path="/terms" element={<TermsOfService />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
